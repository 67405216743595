import {
  // Button,
  Col,
  Modal,
  Radio,
  Row,
  // message,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Location,
  useLocation,
  // useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { CheckOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { isUserNew } from '../../features/auth/authSlice';
// import { useFreeSubscriptionMutation } from '../../features/setting/billingSlice';
import { selectUpgradePlanModal, toggleUpgradePlanModal } from '../../features/ui/uiSlice';
import { useWindowSize } from '../../hooks/useWindowSize';

import 'swiper/css';
import 'swiper/css/navigation';
import Title from 'antd/lib/typography/Title';

interface offerType {
  title: string;
  label?: string;
  price?: {
    monthly: number | null;
    annually: number | null;
  };
  description: string | null;
  offers: string[];
  button_text: string;
  button_text2: string;
  plan_code?: {
    annual: { USD: string; EUR: string };
    monthly: { USD: string; EUR: string };
  };
}

const PlanSelector = ({
  asModal = true,
  isModalVisible,
  user_identification,
  user_email,
  hideFreePlan = false,
  closable,
  setShowPlansModal,
}: {
  asModal?: boolean;
  isModalVisible: boolean;
  user_identification: string;
  user_email?: string;
  hideFreePlan?: boolean;
  closable?: boolean;
  setShowPlansModal: (show: boolean) => void;
}) => {
  const { t, i18n } = useTranslation();
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location: Location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isNewUser = useAppSelector(isUserNew);
  const [windowSize] = useWindowSize();

  const shouldUpgrade = useAppSelector(selectUpgradePlanModal);

  const data = useMemo(() => {
    return [
      {
        title: 'BASIC',
        description: t('MobileGroupedPlans.key45'),
        offers: [
          t('MobileGroupedPlans.key49'),
          t('MobileGroupedPlans.key33'),
          t('MobileGroupedPlans.key20'),
          t('MobileGroupedPlans.key25'),
          t('MobileGroupedPlans.key31'),
          t('MobileGroupedPlans.key38'),
        ],
        button_text: t('MobileGroupedPlans.key47'),
        button_text2: t('MobileGroupedPlans.key47'),
        plan_code: {
          annual: { USD: 'LPBOTC_876KHFD67DF', EUR: 'LPBOTC_876KIRT34DF' },
          monthly: { USD: 'LPBOTC_876KHFD67DF', EUR: 'LPBOTC_876KIRT34DF' },
        },
        price: {
          monthly: 1.0,
          annually: 1.0,
        },
      },
      {
        title: 'BUSINESS',
        price: {
          monthly: 11.0,
          annually: 84.0,
        },
        description: t('MobileGroupedPlans.key44'),
        offers: [
          t('MobileGroupedPlans.key40'),
          t('MobileGroupedPlans.key39'),
          t('MobileGroupedPlans.key15'),
          t('MobileGroupedPlans.key12'),
          t('MobileGroupedPlans.key41'),
        ],
        button_text: isNewUser ? t('MobileGroupedPlans.key51') : t('MobileGroupedPlans.key47'),
        button_text2: t('MobileGroupedPlans.key47'),
        plan_code: {
          annual: {
            USD: isNewUser ? 'LPBOA_BA7C0BO69A' : 'LPBOA_T3LEJ0KSDA',
            EUR: isNewUser ? 'LPBOA_8X5K67FVCK' : 'LPBOA_52H283EZVW',
          },
          monthly: { USD: 'LPBOM_TH9EGTNJ8I', EUR: 'LPBOM_LZSTZINL90' },
        },
      },
      {
        title: 'PREMIUM',
        price: {
          monthly: 37.0,
          annually: 324.0,
        },
        description: t('MobileGroupedPlans.key44'),
        offers: [
          t('MobileGroupedPlans.key42'),
          t('MobileGroupedPlans.key10'),
          t('MobileGroupedPlans.key11'),
          t('MobileGroupedPlans.key30'),
          t('MobileGroupedPlans.key29'),
          t('MobileGroupedPlans.key28'),
          t('MobileGroupedPlans.key32'),
          t('MobileGroupedPlans.key18'),
          t('MobileGroupedPlans.key6'),
          t('MobileGroupedPlans.key19'),
        ],
        button_text: isNewUser ? t('MobileGroupedPlans.key51') : t('MobileGroupedPlans.key47'),
        button_text2: t('MobileGroupedPlans.key47'),
        plan_code: {
          annual: {
            USD: isNewUser ? 'LPBOA_J0BDEYGT7L' : 'LPBOA_NKGD5D6SO0',
            EUR: isNewUser ? 'LPBOA_S34OW3I9DV' : 'LPBOA_KA016PJXP4',
          },
          monthly: { USD: 'LPBOM_2D1AXKCTGD', EUR: 'LPBOM_BXY3TKATXJ' },
        },
      },
      {
        title: 'ENTERPRISE',
        price: {
          monthly: 310.0,
          annually: 2520.0,
        },
        description: t('MobileGroupedPlans.key44'),
        offers: [
          t('MobileGroupedPlans.key43'),
          t('MobileGroupedPlans.key23'),
          t('MobileGroupedPlans.key3'),
          t('MobileGroupedPlans.key27'),
          t('MobileGroupedPlans.key26'),
        ],
        button_text: isNewUser ? t('MobileGroupedPlans.key51') : t('MobileGroupedPlans.key47'),
        button_text2: t('MobileGroupedPlans.key47'),
        plan_code: {
          annual: {
            USD: isNewUser ? 'LPBOM_1NUPLESRUM' : 'LPBOA_EX8YK79D4B',
            EUR: isNewUser ? 'LPBOM_6A18J9T8JW' : 'LPBOA_61HVMK7N60',
          },
          monthly: { USD: 'LPBOM_OGL6KT18EX', EUR: 'LPBOM_OYGH274XS5' },
        },
      },
    ] as offerType[];
  }, [isNewUser, t]);

  const [currency, setCurrency] = useState<'USD' | 'EUR'>('USD');
  const [activePlan, setActivePlan] = useState('');
  const [subsType, setSubsType] = useState('yearly');
  const [my_swiper, set_my_swiper] = useState<any>({});
  const [position, setPosition] = useState({
    isEnd: false,
    isBegining: true,
  });
  const [offers, setOffers] = useState<offerType[]>(data);

  // const [createFreeSubscription, { isLoading }] = useFreeSubscriptionMutation();

  useEffect(() => {
    let offer = searchParams.get('offer') || localStorage.getItem('_lum_offer');
    let subTypeFromOffer = offer?.substring(offer?.lastIndexOf('_') + 1);

    if (offer) {
      setActivePlan(offer);

      localStorage.setItem('_lum_offer', offer);

      if (subTypeFromOffer === 'MONTH') setSubsType('monthly');
      else setSubsType('yearly');
    }
  }, [searchParams]);

  useEffect(() => {
    let cloneOffers = hideFreePlan ? data.filter((offer) => offer.title !== 'BASIC') : data;

    setOffers(cloneOffers);
  }, [data, hideFreePlan]);

  const renderPlanName = {
    LPBOTC_876KHFD67DF: 'BASIC',
    LPBOTC_876KIRT34DF: 'BASIC',
    LPBOA_T3LEJ0KSDA: 'ANNUAL BUSINESS',
    LPBOA_8X5K67FVCK: 'ANNUAL BUSINESS WITH TRIAL',
    LPBOA_52H283EZVW: 'ANNUAL BUSINESS',
    LPBOM_TH9EGTNJ8I: 'MONTHLY BUSINESS',
    LPBOM_LZSTZINL90: 'MONTHLY BUSINESS',
    LPBOA_NKGD5D6SO0: 'ANNUAL PREMIUM',
    LPBOA_S34OW3I9DV: 'ANNUAL PREMIUM WITH TRIAL',
    LPBOA_KA016PJXP4: 'ANNUAL PREMIUM',
    LPBOM_BXY3TKATXJ: 'MONTHLY PREMIUM',
    LPBOM_2D1AXKCTGD: 'MONTHLY PREMIUM',
    LPBOA_EX8YK79D4B: 'ANNUAL ENTERPRISE',
    LPBOM_6A18J9T8JW: 'ANNUAL ENTERPRISE WITH TRIAL',
    LPBOA_61HVMK7N60: 'ANNUAL ENTERPRISE',
    LPBOM_OGL6KT18EX: 'MONTHLY ENTERPRISE',
    LPBOM_OYGH274XS5: 'MONTHLY ENTERPRISE',
    LPBOA_BA7C0BO69A: 'ANNUAL BUSINESS WITH TRIAL',
    LPBOA_J0BDEYGT7L: 'ANNUAL PREMIUM WITH TRIAL',
    LPBOM_1NUPLESRUM: 'ANNUAL ENTERPRISE WITH TRAIL',
  } as { [key: string]: string };

  const renderActiveCssPlan = useCallback(
    (title: string | undefined, plan: string) => {
      if (!location.pathname.includes('/settings/billing') && title && plan === title) return 'active';
      else return '';
    },
    [location.pathname]
  );

  const handleSelectPlan = useCallback(
    (plan: offerType) => {
      if (plan.plan_code) {
        const planCode = subsType === 'monthly' ? plan.plan_code.monthly[currency] : plan.plan_code.annual[currency];

        if (!location.pathname.includes('billing')) {
          setActivePlan(planCode);
          setSearchParams({ plan: planCode });

          localStorage.setItem('_lum_offer', planCode);
        }
      }
    },
    [setSearchParams, subsType, location.pathname, currency]
  );

  // const handleFreePlanSubscription = () => {
  //   createFreeSubscription({ customer_identification: user_identification, email: user_email as string })
  //     .unwrap()
  //     .then((data: any) => {
  //       message.success(data.message || 'Ypu have successfully subscribed to free plan!');

  //       const popunder = window.open(
  //         `${
  //           process.env.REACT_APP_LUMBERJACK_URL +
  //           `/home/${i18n.resolvedLanguage}/welcome?plan_name=LUMBERJACK_FREE_ANNUAL&status=true`
  //         }`,
  //         'newwindow',
  //         'toolbar=no,status=no,width=100,height=100'
  //       );

  //       window.focus();
  //       popunder?.blur();

  //       setShowPlansModal(false);

  //       //TODO =====> REMOVE TIMEOUT AFTER BE MODIFICATION OVER CLIENT PAYMENT VERIFICATION ERROR
  //       setTimeout(() => {
  //         navigate('/');
  //       }, 20000);
  //     });
  // };

  const handleClosePlanSelectorModal = () => {
    setShowPlansModal(false);

    dispatch(toggleUpgradePlanModal());
  };

  const handleLinkToPaymodClicked = (plan_code: string) => {
    localStorage.setItem('_lum_offer', plan_code);

    if (shouldUpgrade) {
      dispatch(toggleUpgradePlanModal());

      window.history.replaceState({}, '');
    }
  };

  useEffect(() => {
    setCurrency(['en', 'cn'].includes(i18n.language) ? 'USD' : 'EUR');

    return () => {};
  }, [i18n.language]);

  if (asModal)
    return (
      <Modal
        closable={closable || false}
        width={1000}
        title={t('plans.Plan selection')}
        open={isModalVisible}
        // onOk={handleOk}
        onCancel={handleClosePlanSelectorModal}
        footer={false}
      >
        <section className="d-flex flex-column section-6 py-5 min-vh-100" id="plans">
          <div className="my-auto">
            <div className="text-center mb-3">
              <Row align="bottom" gutter={[0, 10]}>
                <Col md={12}>
                  <h1 className="lk-text-red mb-4">{t('plans.Our plans')}</h1>
                  <p style={{ fontSize: 16, color: '#54575E', margin: 0 }} className="w-25 m-auto fw-light">
                    {t('plans.We have plans for everyone')} <br />
                    {t('plans.Get up to')} <b className="fw-bold">30%</b> {t('plans.discount with annual payment!')}
                  </p>
                </Col>
                <Col md={12} xs={24}>
                  <Row justify={'end'} gutter={[0, 16]}>
                    {!location.pathname.includes('/settings/billing') && activePlan && (
                      <Col xs={24}>
                        <a
                          href={`${process.env.REACT_APP_PAYMENT_SERVICE}/payment/${
                            renderPlanName[activePlan] === 'BASIC' ? 'charge' : 'subscription'
                          }?template_image_url=${
                            process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL
                          }&redirect_page=${
                            process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE
                          }/control-panel/welcome&user_identification=${user_identification}&payment_plan_identification=${activePlan}&language_code=${
                            i18n.language
                          }&entity_identification=${process.env.REACT_APP_LUMBERJACK_IDENTIFICATION}`}
                          className="lk-red-button"
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
                        >
                          {t('plans.Subscribe')} {renderPlanName[activePlan]} {t('plans.plan')}
                          <svg
                            width="23"
                            height="16"
                            viewBox="0 0 23 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginLeft: 10 }}
                          >
                            <path
                              d="M1 7C0.447715 7 -4.82823e-08 7.44772 0 8C4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM22.7071 8.7071C23.0976 8.31658 23.0976 7.68342 22.7071 7.29289L16.3431 0.928931C15.9526 0.538406 15.3195 0.538407 14.9289 0.928931C14.5384 1.31946 14.5384 1.95262 14.9289 2.34314L20.5858 8L14.9289 13.6569C14.5384 14.0474 14.5384 14.6805 14.9289 15.0711C15.3195 15.4616 15.9526 15.4616 16.3431 15.0711L22.7071 8.7071ZM1 9L22 9L22 7L1 7L1 9Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                      </Col>
                    )}
                    <Col xs={24}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Title level={5}>{t('plans.key13')}</Title>

                        <Radio.Group onChange={(e) => setCurrency(e.target.value as 'USD' | 'EUR')} value={currency}>
                          <Radio value={'USD'}>USD</Radio>
                          <Radio value={'EUR'}>EUR</Radio>
                        </Radio.Group>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="position-relative">
              <div className="lk-subs-type">
                <ul className="list-unstyled">
                  <li className={subsType === 'yearly' ? 'active' : ''} onClick={() => setSubsType('yearly')}>
                    {t('plans.Annually')}
                  </li>
                  <li>|</li>
                  <li className={subsType === 'monthly' ? 'active' : ''} onClick={() => setSubsType('monthly')}>
                    {t('plans.Monthly')}
                  </li>
                </ul>
              </div>
            </div>
            <Row className="lk-offers">
              {(hideFreePlan && windowSize < 980) ||
                (!hideFreePlan && offers.length > 3 && (
                  <div className="lk-offers-nav">
                    <button
                      className="btn btn-sm btn-link fs-5"
                      disabled={position.isBegining}
                      onClick={() => my_swiper.slidePrev()}
                    >
                      <LeftOutlined />
                    </button>
                    <button
                      className="btn btn-sm btn-link fs-5"
                      disabled={position.isEnd}
                      onClick={() => my_swiper.slideNext()}
                    >
                      <RightOutlined />
                    </button>
                  </div>
                ))}

              <Swiper
                className="mt-3"
                spaceBetween={40}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 2.1,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: !hideFreePlan ? 3.2 : 3,
                    spaceBetween: 20,
                  },
                }}
                navigation={false}
                modules={[Navigation]}
                autoHeight={false}
                onSlideChange={() => {
                  if (my_swiper.isEnd) {
                    setPosition({
                      isEnd: true,
                      isBegining: false,
                    });
                  } else if (my_swiper.isBeginning) {
                    setPosition({
                      isEnd: false,
                      isBegining: true,
                    });
                  } else {
                    setPosition({
                      isEnd: false,
                      isBegining: false,
                    });
                  }
                }}
                onInit={(ev: any) => {
                  set_my_swiper(ev);
                }}
              >
                {offers?.map((offer, index) => (
                  <SwiperSlide key={index} className="pb-3" id={index === 0 ? 'free' : ''}>
                    <div className={`lk-offers-card`}>
                      {renderActiveCssPlan(
                        subsType === 'monthly' ? offer.plan_code?.monthly[currency] : offer.plan_code?.annual[currency],
                        activePlan
                      ) === 'active' && <div className="current-active-plan">{t('plans.Your selected plan')}</div>}
                      <div onClick={() => handleSelectPlan(offer)}>
                        <div className="title d-flex align-items-center justify-content-between flex-wrap">
                          <h6 className="fw-normal">{offer.title}</h6>
                          {offer.price && offers[index - 1] && (
                            <div className="badge d-flex align-items-center justify-content-between">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M13.5449 3.35008L11.7885 0.977084C11.5725 0.675112 11.2876 0.428965 10.9575 0.259045C10.6274 0.0891242 10.2616 0.000323004 9.8903 1.02806e-06H4.10947C3.74 -0.000345762 3.37574 0.0870478 3.04665 0.25499C2.71756 0.422931 2.43306 0.66662 2.21655 0.966001L0.428635 3.35592C0.137531 3.7664 -0.0128051 4.26013 0.000129008 4.76319C0.0130631 5.26625 0.188572 5.7516 0.500385 6.14658L5.62905 13.3379C5.79249 13.5449 6.00081 13.712 6.23828 13.8267C6.47575 13.9414 6.73617 14.0007 6.99989 14C7.26813 13.9995 7.53267 13.9374 7.77316 13.8186C8.01364 13.6997 8.22366 13.5273 8.38705 13.3146L13.4749 6.20317C13.8007 5.80241 13.9843 5.30489 13.9969 4.78858C14.0096 4.27226 13.8506 3.76634 13.5449 3.35008V3.35008ZM10.8452 1.66308L12.6057 4.04192C12.6151 4.05475 12.6174 4.06992 12.6267 4.08333H9.77305L8.94005 1.16667H9.8903C10.0775 1.16725 10.2619 1.21261 10.428 1.29897C10.5941 1.38533 10.7372 1.51017 10.8452 1.66308V1.66308ZM6.99989 11.1522L5.42489 5.25H8.57489L6.99989 11.1522ZM5.44005 4.08333L6.27305 1.16667H7.72672L8.55972 4.08333H5.44005ZM3.1598 1.65492C3.26804 1.50406 3.4106 1.38111 3.57573 1.29622C3.74085 1.21132 3.9238 1.16692 4.10947 1.16667H5.05972L4.22672 4.08333H1.35205C1.36022 4.0705 1.36255 4.05475 1.37189 4.0425L3.1598 1.65492ZM1.42789 5.43958C1.38577 5.38009 1.34962 5.31659 1.31997 5.25H4.21855L5.96505 11.8008L1.42789 5.43958ZM8.03297 11.8067L9.78297 5.25H12.6915C12.6539 5.33707 12.6063 5.41949 12.5497 5.49558L8.03297 11.8067Z" />
                              </svg>
                              <p className="mb-0 ms-2">
                                {t('plans.include ') + offers[index - 1]?.title + t('plans. feature')}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="price">
                          {subsType === 'yearly' ? (
                            <>
                              <h3>
                                {!offer.price
                                  ? offer.label
                                  : currency === 'USD'
                                  ? '$' +
                                    (offer.title === 'BASIC'
                                      ? offer.price.annually
                                      : (offer.price.annually as number) / 12)
                                  : (offer.title === 'BASIC'
                                      ? offer.price.annually
                                      : (offer.price.annually as number) / 12) + '€'}
                              </h3>
                              {offer.price && (
                                <>
                                  <span className="offer">
                                    / {offer.title !== 'BASIC' ? t('plans.month') : t('plans.key12')}
                                  </span>
                                  <span className="discount">
                                    <sup>
                                      <del>
                                        {currency === 'USD' ? '$' : ''}
                                        {offer.price.monthly}
                                        {currency === 'EUR' ? '€' : ''}
                                      </del>
                                    </sup>
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <h3>
                              {!offer.price
                                ? offer.label
                                : currency === 'USD'
                                ? '$' + offer.price.monthly
                                : offer.price.monthly + '€'}
                              {offer.price && offer.title !== 'BASIC' ? (
                                <span className="offer">/ {t('plans.month')}</span>
                              ) : (
                                <span className="offer">/ {t('plans.key12')}</span>
                              )}
                            </h3>
                          )}
                        </div>

                        {
                          offer.title === 'BASIC' ? (
                            <div className="desc">
                              <p>{offer.description}</p>
                            </div>
                          ) : null
                          // <div className="desc">
                          //   {subsType === 'monthly' ? (
                          //     <p className="is-monthly">{t('plans.Pay annually and save up to 30%')}</p>
                          //   ) : (
                          //     <p>
                          //       {!offer.price
                          //         ? offers.length !== index + 1
                          //           ? offer.description
                          //           : null
                          //         : `(${t('MobileGroupedPlans.key46')} ${currency === 'USD' ? '$' : ''}${
                          //             offer.price?.annually
                          //           }${currency === 'EUR' ? '€' : ''})`}
                          //     </p>
                          //   )}
                          // </div>
                        }

                        <div className="offers">
                          <ul className="list-unstyled">
                            {offer.title === 'BASIC' && (
                              <React.Fragment>
                                <li>
                                  <div>🔒</div>
                                  <span>{t('MobileGroupedPlans.key52')}</span>
                                </li>
                                <li>
                                  <div>🌳</div>
                                  <span>{t('MobileGroupedPlans.key53')}</span>
                                </li>
                              </React.Fragment>
                            )}

                            {offer.offers.map((offerItem, index) => (
                              <li key={index}>
                                <div>
                                  <CheckOutlined />
                                </div>
                                <span>{offerItem}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="action">
                        <>
                          {subsType === 'monthly' ? (
                            <a
                              onClick={() => handleLinkToPaymodClicked(offer.plan_code?.monthly[currency] as string)}
                              href={`${process.env.REACT_APP_PAYMENT_SERVICE}/payment/${
                                offer.title === 'BASIC' ? 'charge' : 'subscription'
                              }?template_image_url=${
                                process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL
                              }&redirect_page=${
                                process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE
                              }/control-panel/welcome&user_identification=${user_identification}&payment_plan_identification=${
                                offer.plan_code?.monthly[currency]
                              }&language_code=${i18n.language}&entity_identification=${
                                process.env.REACT_APP_LUMBERJACK_IDENTIFICATION
                              }`}
                              className="w-100 lk-red-button"
                            >
                              {offer.button_text2}
                            </a>
                          ) : (
                            <a
                              onClick={() => handleLinkToPaymodClicked(offer.plan_code?.annual[currency] as string)}
                              href={`${process.env.REACT_APP_PAYMENT_SERVICE}/payment/${
                                offer.title === 'BASIC' ? 'charge' : 'subscription'
                              }?template_image_url=${
                                process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL
                              }&redirect_page=${
                                process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE
                              }/control-panel/welcome&user_identification=${user_identification}&payment_plan_identification=${
                                offer.plan_code?.annual[currency]
                              }&language_code=${i18n.language}&entity_identification=${
                                process.env.REACT_APP_LUMBERJACK_IDENTIFICATION
                              }`}
                              className="w-100 lk-red-button"
                            >
                              {offer.button_text}
                            </a>
                          )}
                        </>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Row>
          </div>
        </section>
      </Modal>
    );
  else
    return (
      <section className="d-flex flex-column section-6 py-5 min-vh-100" id="plans">
        <div className="my-auto">
          <div className="text-center mb-3">
            <Row align="bottom" gutter={[0, 10]}>
              <Col md={12}>
                <h1 className="lk-text-red mb-4">{t('plans.Our plans')}</h1>
                <p style={{ fontSize: 16, color: '#54575E', margin: 0 }} className="w-25 m-auto fw-light">
                  {t('plans.We have plans for everyone')} <br />
                  {t('plans.Get up to')} <b className="fw-bold">30%</b> {t('plans.discount with annual payment!')}
                </p>
              </Col>

              <Col md={12} xs={24}>
                <Row justify={'end'} gutter={[0, 16]}>
                  {!location.pathname.includes('/settings/billing') && activePlan && (
                    <Col xs={24}>
                      <a
                        href={`${process.env.REACT_APP_PAYMENT_SERVICE}/payment/${
                          renderPlanName[activePlan] === 'BASIC' ? 'charge' : 'subscription'
                        }?template_image_url=${
                          process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL
                        }&redirect_page=${
                          process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE
                        }/control-panel/welcome&user_identification=${user_identification}&payment_plan_identification=${activePlan}&language_code=${
                          i18n.language
                        }&entity_identification=${process.env.REACT_APP_LUMBERJACK_IDENTIFICATION}`}
                        className="lk-red-button"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
                      >
                        {t('plans.Subscribe')} {renderPlanName[activePlan]} {t('plans.plan')}
                        <svg
                          width="23"
                          height="16"
                          viewBox="0 0 23 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginLeft: 10 }}
                        >
                          <path
                            d="M1 7C0.447715 7 -4.82823e-08 7.44772 0 8C4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM22.7071 8.7071C23.0976 8.31658 23.0976 7.68342 22.7071 7.29289L16.3431 0.928931C15.9526 0.538406 15.3195 0.538407 14.9289 0.928931C14.5384 1.31946 14.5384 1.95262 14.9289 2.34314L20.5858 8L14.9289 13.6569C14.5384 14.0474 14.5384 14.6805 14.9289 15.0711C15.3195 15.4616 15.9526 15.4616 16.3431 15.0711L22.7071 8.7071ZM1 9L22 9L22 7L1 7L1 9Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </Col>
                  )}
                  <Col xs={24}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'end',
                      }}
                    >
                      <Title level={5}>{t('plans.key13')}</Title>

                      <Radio.Group onChange={(e) => setCurrency(e.target.value as 'USD' | 'EUR')} value={currency}>
                        <Radio value={'USD'}>USD</Radio>
                        <Radio value={'EUR'}>EUR</Radio>
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="position-relative">
            <div className="lk-subs-type">
              <ul className="list-unstyled">
                <li className={subsType === 'yearly' ? 'active' : ''} onClick={() => setSubsType('yearly')}>
                  {t('plans.Annually')}
                </li>
                <li>|</li>
                <li className={subsType === 'monthly' ? 'active' : ''} onClick={() => setSubsType('monthly')}>
                  {t('plans.Monthly')}
                </li>
              </ul>
            </div>
          </div>
          <Row className="lk-offers">
            {((hideFreePlan && windowSize < 1024 && offers && offers.length <= 3) || !hideFreePlan) && (
              <div className="lk-offers-nav">
                <button
                  className="btn btn-sm btn-link fs-5"
                  disabled={position.isBegining}
                  onClick={() => my_swiper.slidePrev()}
                >
                  <LeftOutlined />
                </button>
                <button
                  className="btn btn-sm btn-link fs-5"
                  disabled={position.isEnd}
                  onClick={() => my_swiper.slideNext()}
                >
                  <RightOutlined />
                </button>
              </div>
            )}

            <Swiper
              className="mt-3"
              spaceBetween={40}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 2.1,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: !hideFreePlan ? 2.2 : 2,
                  spaceBetween: 20,
                },
              }}
              navigation={false}
              modules={[Navigation]}
              autoHeight={false}
              onSlideChange={() => {
                if (my_swiper.isEnd) {
                  setPosition({
                    isEnd: true,
                    isBegining: false,
                  });
                } else if (my_swiper.isBeginning) {
                  setPosition({
                    isEnd: false,
                    isBegining: true,
                  });
                } else {
                  setPosition({
                    isEnd: false,
                    isBegining: false,
                  });
                }
              }}
              onInit={(ev: any) => {
                set_my_swiper(ev);
              }}
            >
              {offers.map((offer, index) => (
                <SwiperSlide key={index} className="pb-3" id={index === 0 ? 'free' : ''}>
                  <div className={`lk-offers-card`}>
                    {renderActiveCssPlan(
                      subsType === 'monthly' ? offer.plan_code?.monthly[currency] : offer.plan_code?.annual[currency],
                      activePlan
                    ) === 'active' && <div className="current-active-plan">{t('plans.Your selected plan')}</div>}
                    <div onClick={() => handleSelectPlan(offer)}>
                      <div className="title d-flex align-items-center justify-content-between flex-wrap">
                        <h6 className="fw-normal">{offer.title}</h6>
                        {offer.price && offers[index - 1] && (
                          <div className="badge d-flex align-items-center justify-content-between">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.5449 3.35008L11.7885 0.977084C11.5725 0.675112 11.2876 0.428965 10.9575 0.259045C10.6274 0.0891242 10.2616 0.000323004 9.8903 1.02806e-06H4.10947C3.74 -0.000345762 3.37574 0.0870478 3.04665 0.25499C2.71756 0.422931 2.43306 0.66662 2.21655 0.966001L0.428635 3.35592C0.137531 3.7664 -0.0128051 4.26013 0.000129008 4.76319C0.0130631 5.26625 0.188572 5.7516 0.500385 6.14658L5.62905 13.3379C5.79249 13.5449 6.00081 13.712 6.23828 13.8267C6.47575 13.9414 6.73617 14.0007 6.99989 14C7.26813 13.9995 7.53267 13.9374 7.77316 13.8186C8.01364 13.6997 8.22366 13.5273 8.38705 13.3146L13.4749 6.20317C13.8007 5.80241 13.9843 5.30489 13.9969 4.78858C14.0096 4.27226 13.8506 3.76634 13.5449 3.35008V3.35008ZM10.8452 1.66308L12.6057 4.04192C12.6151 4.05475 12.6174 4.06992 12.6267 4.08333H9.77305L8.94005 1.16667H9.8903C10.0775 1.16725 10.2619 1.21261 10.428 1.29897C10.5941 1.38533 10.7372 1.51017 10.8452 1.66308V1.66308ZM6.99989 11.1522L5.42489 5.25H8.57489L6.99989 11.1522ZM5.44005 4.08333L6.27305 1.16667H7.72672L8.55972 4.08333H5.44005ZM3.1598 1.65492C3.26804 1.50406 3.4106 1.38111 3.57573 1.29622C3.74085 1.21132 3.9238 1.16692 4.10947 1.16667H5.05972L4.22672 4.08333H1.35205C1.36022 4.0705 1.36255 4.05475 1.37189 4.0425L3.1598 1.65492ZM1.42789 5.43958C1.38577 5.38009 1.34962 5.31659 1.31997 5.25H4.21855L5.96505 11.8008L1.42789 5.43958ZM8.03297 11.8067L9.78297 5.25H12.6915C12.6539 5.33707 12.6063 5.41949 12.5497 5.49558L8.03297 11.8067Z" />
                            </svg>
                            <p className="mb-0 ms-2">
                              {t('plans.include ') + offers[index - 1]?.title + t('plans. feature')}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="price">
                        {subsType === 'yearly' ? (
                          <>
                            <h3>
                              {!offer.price
                                ? offer.label
                                : currency === 'USD'
                                ? '$' +
                                  (offer.title === 'BASIC'
                                    ? offer.price.annually
                                    : (offer.price.annually as number) / 12)
                                : (offer.title === 'BASIC'
                                    ? offer.price.annually
                                    : (offer.price.annually as number) / 12) + '€'}
                            </h3>
                            {offer.price && (
                              <>
                                <span className="offer">
                                  / {offer.title !== 'BASIC' ? t('plans.month') : t('plans.key12')}
                                </span>
                                <span className="discount">
                                  <sup>
                                    <del>
                                      {currency === 'USD' ? '$' : ''}
                                      {offer.price.monthly}
                                      {currency === 'EUR' ? '€' : ''}
                                    </del>
                                  </sup>
                                </span>
                              </>
                            )}
                          </>
                        ) : (
                          <h3>
                            {!offer.price
                              ? offer.label
                              : currency === 'USD'
                              ? '$' + offer.price.monthly
                              : offer.price.monthly + '€'}
                            {offer.price && offer.title !== 'BASIC' ? (
                              <span className="offer">/ {t('plans.month')}</span>
                            ) : (
                              <span className="offer">/ {t('plans.key12')}</span>
                            )}
                          </h3>
                        )}
                      </div>
                      {
                        offer.title === 'BASIC' ? (
                          <div className="desc">
                            <p>{offer.description}</p>
                          </div>
                        ) : null
                        // <div className="desc">
                        //   {subsType === 'monthly' ? (
                        //     <p className="is-monthly">{t('plans.Pay annually and save up to 30%')}</p>
                        //   ) : (
                        //     <p>
                        //       {!offer.price
                        //         ? offers.length !== index + 1
                        //           ? offer.description
                        //           : null
                        //         : `(Annual one-time charge of $${offer.price?.annually})`}
                        //     </p>
                        //   )}
                        // </div>
                      }
                      <div className="offers">
                        <ul className="list-unstyled">
                          {offer.title === 'BASIC' && (
                            <React.Fragment>
                              <li>
                                <div>🔒</div>
                                <span>{t('MobileGroupedPlans.key52')}</span>
                              </li>
                              <li>
                                <div>🌳</div>
                                <span>{t('MobileGroupedPlans.key53')}</span>
                              </li>
                            </React.Fragment>
                          )}
                          {offer.offers.map((offerItem, index) => (
                            <li key={index}>
                              <div>
                                <CheckOutlined />
                              </div>
                              <span>{offerItem}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="action">
                      <div>
                        {subsType === 'monthly' ? (
                          <a
                            onClick={() => handleLinkToPaymodClicked(offer.plan_code?.monthly[currency] as string)}
                            href={`${process.env.REACT_APP_PAYMENT_SERVICE}/payment/${
                              offer.title === 'BASIC' ? 'charge' : 'subscription'
                            }?template_image_url=${
                              process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL
                            }&redirect_page=${
                              process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE
                            }/control-panel/welcome&user_identification=${user_identification}&payment_plan_identification=${
                              offer.plan_code?.monthly[currency]
                            }&language_code=${i18n.language}&entity_identification=${
                              process.env.REACT_APP_LUMBERJACK_IDENTIFICATION
                            }`}
                            className="w-100 lk-red-button"
                          >
                            {offer.button_text2}
                          </a>
                        ) : (
                          <a
                            onClick={() => handleLinkToPaymodClicked(offer.plan_code?.annual[currency] as string)}
                            href={`${process.env.REACT_APP_PAYMENT_SERVICE}/payment/${
                              offer.title === 'BASIC' ? 'charge' : 'subscription'
                            }?template_image_url=${
                              process.env.REACT_APP_PAYMENT_SERVICE_TEMPLATE_IMAGE_URL
                            }&redirect_page=${
                              process.env.REACT_APP_PAYMENT_SERVICE_REDIRECT_PAGE
                            }/control-panel/welcome&user_identification=${user_identification}&payment_plan_identification=${
                              offer.plan_code?.annual[currency]
                            }&language_code=${i18n.language}&entity_identification=${
                              process.env.REACT_APP_LUMBERJACK_IDENTIFICATION
                            }`}
                            className="w-100 lk-red-button"
                          >
                            {offer.button_text}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </div>
      </section>
    );
};

export default PlanSelector;
